html, body, #root, .app, .home, .main_content, .content_div {
    min-height: 100% !important;
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    text-align: center;
    color: #000;
}

p {
    margin: unset;
}

iframe {
    display: none;
}

.hash_text {
    font-family: 'Space Mono', monospace;
    font-size: 18px;
    line-height: 130%;
    text-align: center;
    font-feature-settings: 'zero' on;
    color: #FFFFFF;
    max-width: 100%;
    display: flex;
    align-items: center;
}

.hash_text .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: -1px;
}

/* scroll bar */
html::-webkit-scrollbar,
.scroll_bar::-webkit-scrollbar {
    width: 16px;
}

html::-webkit-scrollbar-thumb,
.scroll_bar::-webkit-scrollbar-thumb {
    height: 56px;
    border-radius: 8px;
    border: 6px solid transparent;
    background-clip: content-box;
    background-image: linear-gradient(90deg, #8B59FD -17.07%, #6228E6 120.73%);
}

@media (max-width: 768px) {
    .hash_text .name {
        width: 100px;
    }
}
